import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as CookieConsent from 'vanilla-cookieconsent';
import Cookies from 'js-cookie';

const pluginConfig = {
  guiOptions: {
    consentModal: {
      layout: 'box',
      position: 'bottom left',
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: 'box',
      position: 'right',
      equalWeightButtons: false,
      flipButtons: true,
    },
  },

  onConsent: function () {
    Cookies.set('ng-cc-accepted', '1');

    if (CookieConsent.acceptedCategory('analytics')) {
      Cookies.set('ng-cc-analytics', '1');
    } else {
      Cookies.set('ng-cc-analytics', '0');
    }

    if (CookieConsent.acceptedCategory('marketing')) {
      Cookies.set('ng-cc-marketing', '1');
    } else {
      Cookies.set('ng-cc-marketing', '0');
    }

    window.dataLayer.push({
      event: 'ngcc-changed',
      page: {
        url: window.location.href,
        title: document.title,
      },
    });
  },

  onChange: function ({ changedCategories }) {
    if (changedCategories.includes('analytics')) {
      if (CookieConsent.acceptedCategory('analytics')) {
        Cookies.set('ng-cc-analytics', '1');
      } else {
        Cookies.set('ng-cc-analytics', '0');
      }
    }

    if (changedCategories.includes('marketing')) {
      if (CookieConsent.acceptedCategory('marketing')) {
        Cookies.set('ng-cc-marketing', '1');
      } else {
        Cookies.set('ng-cc-marketing', '0');
      }
    }

    window.dataLayer.push({
      event: 'ngcc-changed',
      page: {
        url: window.location.href,
        title: document.title,
      },
    });
  },

  categories: {
    necessary: {
      readOnly: true,
      enabled: true,
    },
    analytics: {
      autoClear: {
        cookies: [
          {
            name: /^(_ga|_gid)/,
          },
        ],
      },
    },
    marketing: {},
  },

  language: {
    default: 'hr',

    translations: {
      hr: {
        consentModal: {
          description:
            'Da bi ova web-stranica mogla pravilno funkcionirati i da bismo unaprijedili vaše korisničko iskustvo, koristimo kolačiće.',
          acceptAllBtn: 'Prihvati sve',
          showPreferencesBtn: 'Prilagodi postavke',
          footer: `
                        <a href="/policies/privacy-policy">Politika privatnosti</a>
                        <a href="/policies/terms-of-service">Uvjeti korištenja</a>
                    `,
        },
        preferencesModal: {
          title: 'Postavke kolačića',
          acceptAllBtn: 'Prihvati sve',
          savePreferencesBtn: 'Spremi postavke',
          closeIconLabel: 'Zatvori',
          sections: [
            {
              title: 'Nužni kolačići',
              description:
                'Nužni kolačići omogućuju osnovne funkcionalnosti. Bez ovih kolačića, web-stranica ne može pravilno funkcionirati, a isključiti ih možete mijenjanjem postavki u svome web-pregledniku.',
              linkedCategory: 'necessary',
            },
            {
              title: 'Analitički kolačići',
              linkedCategory: 'analytics',
              description:
                'Analitički kolačići pomažu nam unaprijediti web-stranicu prikupljanjem i analizom podataka o njezinu korištenju.',
            },
            {
              title:
                'Marketinške kolačiće koristimo radi povećanja relevantnosti oglasa koje primate.',
              linkedCategory: 'marketing',
              description:
                'Marketinške kolačiće koristimo radi povećanja relevantnosti oglasa koje primate.',
            },
          ],
        },
      },
    },
  },
  disablePageInteraction: true,
};

window.onload = () => {
  console.log('init cookie');
  CookieConsent.run(pluginConfig);
};
